import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Content from '../components/content';

const Services = () => {
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
      </Helmet>
      <Layout>
        <Content>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium quibusdam corporis aspernatur quae
          deserunt harum tenetur, consequuntur nulla perspiciatis modi.
        </Content>
      </Layout>
    </Fragment>
  );
};

export default Services;
